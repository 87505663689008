<app-user-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters" [showActiveFilter]="!canSelect">
</app-user-filters>
<mat-card class="u-center-text" *ngIf="!(users | async).length">
  <h4>Nessun utente trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addUser()">Aggiungi</button>
</mat-card>
<div class="table-container" [hidden]="!(users | async).length">
  <table mat-table matSort [dataSource]="users" multiTemplateDataRows>
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef> Attivo </th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.active">done_outline</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
      <td mat-cell *matCellDef="let row">
        <strong>{{row.companyName}}</strong>
        <br/>
        <span >{{ row.fullName }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let row">
        {{row.email}}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef> Ruolo </th>
      <td mat-cell *matCellDef="let row">
        {{row.role | userRole }}
      </td>
    </ng-container>
    <ng-container matColumnDef="sponsorCode">
      <th mat-header-cell *matHeaderCellDef> Codice sponsor </th>
      <td mat-cell *matCellDef="let row">
        {{row.sponsorCode}}
      </td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef> Saldo </th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.balance">{{row.balance | customDecimal}} Auram</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addUser()">
          <mat-icon matTooltip="Aggiungi">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editUser(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
            <button *ngIf="row.affiliate" mat-menu-item (click)="addTransaction(row)">
              <mat-icon>add_circle</mat-icon>
              Ricarica
            </button>

          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectUser(row)">Seleziona</button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail">

          <div class="element-info">
            <h4 style="margin-bottom: 16px;">Informazioni utente </h4>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between flex-start">
              <p *ngIf="element.address">Indirizzo: {{element.address}}</p>
              <p *ngIf="element.CAP">CAP: {{element.CAP}}</p>
              <p *ngIf="element.city">Città: {{element.city}}</p>
              <p *ngIf="element.province">Provincia: {{element.province}}</p>
              <p *ngIf="element.country">Nazione: {{element.country}}</p>
              <p *ngIf="element.VAT">PIVA: {{element.VAT}}</p>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
