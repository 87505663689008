

import { AbstractControl } from '@angular/forms';
import { Base, BaseDTO } from './base.model';

export type Role = "admin" | "affiliate";

export interface UserFilters {
  search?: string;
  roles?: Role[];
  active?: boolean;
  fromBalance?: number;
  toBalance?: number;
}

export interface UserDTO extends BaseDTO {
  name: string;
  lastname: string;
  email: string;
  password: string;
  user_roles: Role[];
  active?: boolean;
  company_name?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  CAP?: string;
  VAT?: string;
  phone?: string;
  balance: number;
  sponsor_code: string;
}

export class User extends Base {
  name: string;
  lastname: string;
  email: string;
  password: string;
  role: Role;
  active?: boolean;
  companyName?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  CAP?: string;
  VAT?: string;
  phone?: string;
  balance: number;
  sponsorCode: string;
  constructor(source: UserDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.lastname = source.lastname;
      this.email = source.email;
      this.password = source.password;
      this.balance = source.balance;
      if (source.user_roles) {
        this.role = source.user_roles[0];
      }

      this.active = source.active;
      this.companyName = source.company_name;
      this.address = source.address;
      this.city = source.city;
      this.province = source.province;
      this.country = source.country;
      this.CAP = source.CAP;
      this.VAT = source.VAT;
      this.phone = source.phone;
      this.sponsorCode = source.sponsor_code;
    }
  }

  toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    result.name = this.name;
    result.lastname = this.lastname;
    result.email = this.email;
    result.password = this.password;
    result.balance = this.balance;
    result.user_roles = [];
    if (this.role) {
      result.user_roles.push(this.role);
    }
    result.active = this.active;
    result.company_name = this.companyName;
    result.address = this.address;
    result.city = this.city;
    result.province = this.province;
    result.country = this.country;
    result.CAP = this.CAP;
    result.VAT = this.VAT;
    result.phone = this.phone;
    result.sponsor_code = this.sponsorCode;

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: User) {
    const formModel = formGroup.value;
    let user: User = new User(null);
    user.name = formModel.name;
    user.lastname = formModel.lastname;
    user.email = formModel.email;
    user.password = formModel.password;
    user.role = formModel.role;
    user.active = formModel.active;
    user.companyName = formModel.companyName;
    user.address = formModel.address;
    user.city = formModel.city;
    user.province = formModel.province;
    user.country = formModel.country;
    user.CAP = formModel.CAP;
    user.VAT = formModel.VAT;
    user.phone = formModel.phone;
    user.balance = formModel.balance;
    user.sponsorCode = formModel.sponsorCode;

    if (original) {
      user.id = original.id;
    }
    return user;
  }

  get admin(): boolean {
    return this.hasRole("admin");
  }

  get affiliate(): boolean {
    return this.hasRole("affiliate");
  }

  hasRole(role: Role): boolean {
    return this.role && this.role === role;
  }

  get fullName(): string {
    return `${this.name} ${this.lastname}`;
  }
}
