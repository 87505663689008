import { scanTransactionCode } from './../actions/transaction.actions';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelTransactionService } from '../../commons/services/backend/laravel-transaction.service';

import * as TransactionActions from '../actions/transaction.actions';
import * as UserActions from '../actions/user.actions';
import * as AuthActions from '../actions/auth.actions';
import { AppState } from '../reducers';
import { getScanDialogId, getSelectedTransaction, getSelectionDialogId, getTransactionDialogId, getTransactionsTableState } from '../selectors/transaction.selectors';
import { TransactionEditComponent } from 'src/app/modules/home/transactions/transaction-edit/transaction-edit.component';
import { getUsersTableState } from '../selectors/user.selectors';
import { saveAs } from 'file-saver';
import { TransactionScanComponent } from 'src/app/modules/home/transaction-scan/transaction-scan.component';


@Injectable()
export class TransactionEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.saveTransactionFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.loadTransactions),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.transactionService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              TransactionActions.loadTransactionsCompleted({ transactions: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(TransactionActions.loadTransactionsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.changePage),
      withLatestFrom(this.store$.select(getTransactionsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => TransactionActions.loadTransactions({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.changeSort),
      withLatestFrom(this.store$.select(getTransactionsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => TransactionActions.loadTransactions({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.changeFilters),
      withLatestFrom(this.store$.select(getTransactionsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => TransactionActions.loadTransactions({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editTransaction$ = createEffect(() => this.actions$.pipe(
    ofType(TransactionActions.editTransaction),
    map(({ transaction }) => {
      let dialogRef = this.dialog.open(TransactionEditComponent, {
        data: {
          transaction
        },
      });
      return TransactionActions.transactionDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  addTransaction$ = createEffect(() => this.actions$.pipe(
    ofType(TransactionActions.addTransaction),
    map((user) => {
      let dialogRef = this.dialog.open(TransactionEditComponent, {
        data: {
          user
        },
        width: "60%"
      });
      return TransactionActions.transactionDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveTransaction$ = createEffect(() =>
  this.actions$.pipe(
    ofType(TransactionActions.saveTransaction),
    mergeMap(({ transaction }) =>
      this.transactionService.upsert(transaction.toDTO())
        .pipe(
          map(result =>
            TransactionActions.saveTransactionCompleted({ transaction: result })
          ),
          catchError(error => of(TransactionActions.saveTransactionFailed({ error })))
        )
    )
  )
);

onSaveCompleted$ = createEffect(() =>
  this.actions$.pipe(
    ofType(TransactionActions.saveTransactionCompleted),
    map(action => action.transaction),
    tap(transaction => this.alertService.showConfirmMessage(`Transazione ${transaction.id} salvata con successo`)),
    map(() => TransactionActions.closeTransactionDialog())
  )
);

  deleteTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.deleteTransaction),
      switchMap(({ transaction }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare la transazione ${transaction.id}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.transactionService.delete(transaction.id)
                  .pipe(
                    map(() => TransactionActions.deleteTransactionCompleted({ transaction })),
                    catchError(error => of(TransactionActions.deleteTransactionFailed({ error })))
                  )

                : of(TransactionActions.deleteTransactionCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.deleteTransactionCompleted),
      tap(({ transaction }) => this.alertService.showConfirmMessage(`Transazione ${transaction.id} eliminata con successo`)),
      map(() => TransactionActions.closeTransactionDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.closeTransactionDialog),
      withLatestFrom(this.store$.select(getTransactionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.saveTransactionCompleted),
      withLatestFrom(this.store$.select(getTransactionsTableState)),
      mergeMap(([_, { currentPage, perPage, direction, order, filters, includes }]) => [
        TransactionActions.loadTransactions({ page: currentPage, perPage, order, direction, filters, includes }),
        AuthActions.loadCurrentUser()
      ]
      
      )
    )
  );
  reloadUsersAfterSave = createEffect(() =>
  this.actions$.pipe(
    ofType(TransactionActions.saveTransactionCompleted),
    withLatestFrom(this.store$.select(getUsersTableState)),
    map(([_, { currentPage, perPage, direction, order, filters, includes }]) => UserActions.loadUsers({ page: currentPage, perPage, order, direction, filters, includes }))
  )
);

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.deleteTransactionCompleted),
      withLatestFrom(this.store$.select(getTransactionsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => TransactionActions.loadTransactions({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  closeSelectionDialog = createEffect(() =>
  this.actions$.pipe(
    ofType(TransactionActions.closeSelectionDialog),
    withLatestFrom(this.store$.select(getSelectionDialogId)),
    tap(([_, dialogId]) => {
      if (dialogId) {
        this.dialog.getDialogById(dialogId).close();
      }

    })
  ), { dispatch: false }
);

  transactionsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.transactionSelected),
      map(() => TransactionActions.closeSelectionDialog())
    ))

    exportRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.exportRecords),
      switchMap(( {filters}) =>
        this.transactionService.exportTransactions(filters).pipe(
          map((result) =>
            TransactionActions.exportRecordsCompleted({
              blob: new Blob([result], { type: "text/csv" }),
            })
          ),
          tap(() =>
            this.alertService.showConfirmMessage(
              `Esportazione stato portafoglio generata con successo`
            )
          ),
          catchError((error) =>
            of(TransactionActions.exportRecordsFailed({ error }))
          )
        )
      )
    )
  );

  downloadrecords$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TransactionActions.exportRecordsCompleted),
        tap(({ blob }) => {
          saveAs(blob, `stato-portafoglio.csv`);
        })
      ),
    { dispatch: false }
  );

  scanTransactionCode$ = createEffect(() => this.actions$.pipe(
    ofType(TransactionActions.scanTransactionCode),
    map(() => {
      let dialogRef = this.dialog.open(TransactionScanComponent);
      return TransactionActions.scanDialogOpened({ scanDialogId: dialogRef.id });
    }))
  );

  scanSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(TransactionActions.scanSuccess),
    tap(() =>
    this.alertService.showConfirmMessage(
     " ✔️ La scansione del QR Code è stata effettuata con successo."
    )

    ),
    map(()=> TransactionActions.closeScanDialog()),
    ));

  scanError$ = createEffect(
  () =>
    this.actions$.pipe(
      ofType(TransactionActions.scanError),
      tap((_) =>
      this.alertService.showConfirmMessage(
        " ⚠️ La scansione del QR Code non è andata a buon fine."
      )
      )
    ),
  { dispatch: false }
  );

  closeScanDialog = createEffect(() =>
  this.actions$.pipe(
    ofType(TransactionActions.closeScanDialog),
    withLatestFrom(this.store$.select(getScanDialogId)),
    tap(([_, dialogId]) => {
      if (dialogId) {
        this.dialog.getDialogById(dialogId).close();
      }

    })
    ), { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private transactionService: LaravelTransactionService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
