
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import * as TransactionActions from 'src/app/store/actions/transaction.actions';

import { AppState } from 'src/app/store/reducers';


@Component({
  selector: "app-transaction-scan",
  templateUrl: "./transaction-scan.component.html",
  styleUrls: ["./transaction-scan.component.scss"],
})
export class TransactionScanComponent implements OnInit {
  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  @ViewChild("scanner", { static: true }) scanner: ZXingScannerComponent;

  hasDevices = false;
  hasPermission: boolean;
  qrResultString: string;

  currentDevice: MediaDeviceInfo = null;

 

  ngOnInit() {
    
  }
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    console.log('camera found');
    this.hasDevices = Boolean(devices && devices.length);
  }

  handleQrCodeResult(resultString: string) {

    if(resultString) {
      console.log("Result: ", resultString);
      this.qrResultString = resultString;
      this.store$.dispatch(TransactionActions.scanSuccess({ code: this.qrResultString }));
  
    } else {
      this.store$.dispatch(TransactionActions.scanError());
    }
     
  }

  close() {
    this.store$.dispatch(TransactionActions.closeScanDialog());
  }
}
