import { createSelector } from '@ngrx/store';

import { selectTransactionState } from '../reducers';
import { TransactionState } from '../reducers/transaction.reducer';

// Transaction Selectors

export const getTransactionsTableState = createSelector(
  selectTransactionState,
  (state: TransactionState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getTransactions = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.list
);

export const getTotalTransactions = createSelector(
  getTransactionsTableState,
  (tableState) => tableState.total
);

export const getTransactionsCurrentPage = createSelector(
  getTransactionsTableState,
  (tableState) => tableState.currentPage
);

export const getTransactionsPerPage = createSelector(
  getTransactionsTableState,
  (tableState) => tableState.perPage
);

export const getTransactionsOrder = createSelector(
  getTransactionsTableState,
  (tableState) => tableState.order
);

export const getTransactionsDirection = createSelector(
  getTransactionsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getTransactionsTableState,
  (tableState) => tableState.filters
);

export const getTransactionDialogId = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.dialogId
);

export const getSelectionDialogId = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.selectionDialogId
);
export const getSelectedTransaction = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.transaction
);
export const getCurrentFormControlName = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.currentFormControlName
);
export const getScanDialogId = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.scanDialogId
);
export const getScanCode = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.code
);