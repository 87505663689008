import { scanTransactionCode } from './../../../../store/actions/transaction.actions';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Transaction } from 'src/app/commons/models/transaction.model';
import { User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { PivaValidator } from 'src/app/commons/validators/piva.validator';
import * as TransactionActions from 'src/app/store/actions/transaction.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import * as TransactionSelectors from 'src/app/store/selectors/transaction.selectors';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-transaction-edit',
  templateUrl: './transaction-edit.component.html',
  styleUrls: ['./transaction-edit.component.scss']
})
export class TransactionEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _transaction: Transaction;
  user: User;
  currentUser: User;

  transactionForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.store$.pipe(select(AuthSelectors.getCurrentUser), takeUntil(this.unsubscribe$), map(dto => dto ? new User(dto) : null)).subscribe(user => this.currentUser = user);
    
  }

  ngOnInit() {
    if (this.data) {
      this.transaction = this.data.transaction;
    }
    if (this.data && this.data.user) {
      this.user = this.data.user.user; 
    } 
    this.store$.pipe(select(TransactionSelectors.getScanCode), takeUntil(this.unsubscribe$)).subscribe(code => {
      if(code) {
        this.transactionForm.patchValue({
          clientCode: code
        }) 
      }
    }

      );
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      user: ["", Validators.required],
      amount: ["", Validators.required],
      date: ["", Validators.required],
      note: [],
      clientCode: [],
    }
    this.transactionForm = this.fb.group(group);
    if(this.currentUser?.role !=='admin') {
      this.transactionForm.get('clientCode').setValidators(Validators.required);
      this.transactionForm.get('amount').setValidators([Validators.required, Validators.max(this.currentUser?.balance)])
    } else {
      this.transactionForm.get('clientCode').clearValidators;
      this.transactionForm.get('amount').clearValidators;
    }
    this.transactionForm.get('clientCode').updateValueAndValidity();
    this.transactionForm.get('amount').updateValueAndValidity();
  }

  setTwoNumberDecimal($event) {
    $event.target.value = parseFloat($event.target.value).toFixed(2);
  }

  private ngOnChanges() {
    if (this.transactionForm) {
      this.transactionForm.reset();
      if (this.transaction) {
        this._initFormValues(this.transaction);
      } else {
        this._initFormValues();
      }
    }

  }

  private _initFormValues(transaction?: Transaction) {
    if (transaction) {
      this.transactionForm.patchValue({
        user: transaction.user,
        amount: transaction.amount,
        date: transaction.date,
        note: transaction.note,
        clientCode: transaction.clientCode,
      });
    } else {
      this.transactionForm.patchValue({
        user: this.user? this.user : this.currentUser,
        date: new Date()
      });
    }
  }

  get transaction(): Transaction {
    return this._transaction;
  }

  set transaction(transaction: Transaction) {
    this._transaction = transaction;
    this.ngOnChanges();
  }

  private _prepareSaveTransaction(): Transaction {
    let savingTransaction: Transaction = Transaction.fromFormGroup(this.transactionForm, this.transaction);
    return savingTransaction;
  }

  save() {
    let unsavedEntity = this._prepareSaveTransaction();
    let value = parseFloat(this.transactionForm?.get('amount').value).toFixed(2);
    this.alertService
    .showConfirmDialog(
      "Conferma ricarica",
      this.currentUser.role == 'admin'? `Sei sicuro di voler ricaricare ${value} auram  l'esercente ${this.transactionForm.get('user').value?.fullName}?` : 
      `Sei sicuro di voler ricaricare ${value} auram  il cliente ${this.transactionForm.get('clientCode').value}?`
    )
    .subscribe(result => {
      if (result) {
        this.store$.dispatch(TransactionActions.saveTransaction({ transaction: unsavedEntity }));
      }
    });
  }

  close() {
    if (this.transactionForm.pristine) {
      this.store$.dispatch(TransactionActions.closeTransactionDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(TransactionActions.closeTransactionDialog())
          }
        });
    }
  }

  deleteTransaction() {
    if (this.transaction) {
      this.store$.dispatch(TransactionActions.deleteTransaction({ transaction: this.transaction.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
  scan() {
    this.store$.dispatch(TransactionActions.scanTransactionCode())
  }
}
