<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Codice cliente</mat-label>
        <input type="text" matInput formControlName="search">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Tipo ricarica</mat-label>
        <mat-select formControlName="type">
          <mat-option [value]="">-- Tutti --</mat-option>
          <mat-option [value]="true">Accredito</mat-option>
          <mat-option [value]="false">Scarico</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Importo da</mat-label>
        <input type="number" matInput formControlName="fromAmount" (change)="setTwoNumberDecimal($event)">
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Importo a</mat-label>
        <input type="number" matInput formControlName="toAmount" (change)="setTwoNumberDecimal($event)">
      </mat-form-field>

    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Da</mat-label>
        <input matInput autocomplete="off" [ngxMatDatetimePicker]="from"
        formControlName="from">
      <mat-datepicker-toggle matSuffix [for]="$any(from)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #from [showSeconds]="true" color="primary"
        [enableMeridian]="false"></ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>A</mat-label>
        <input matInput autocomplete="off" [ngxMatDatetimePicker]="to"
        formControlName="to">
      <mat-datepicker-toggle matSuffix [for]="$any(to)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #to [showSeconds]="true" color="primary"
        [enableMeridian]="false"></ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width" *ngIf="currentUser?.role == 'admin'">
        <mat-label>Utente</mat-label>
        <app-user-field formControlName="user"></app-user-field>
      </mat-form-field>
    </div>
    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">Filtra</button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>
