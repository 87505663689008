import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Transaction } from '../../../commons/models/transaction.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { TransactionFilters } from './../../../commons/models/transaction.model';

export type TransactionsColumn = "user"| "transactionDate"| "transactionTime"| "amount"| "clientCode";

@Component({
  selector: 'transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
    ]),
  ],
 

})
export class TransactionListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  transactions: Observable<Transaction[]>;

  @Input()
  defaultFilters: TransactionFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Transaction | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: TransactionsColumn[] = ["user", "transactionDate", "transactionTime", "amount", "clientCode",];;

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<TransactionFilters> = new EventEmitter<TransactionFilters>();

  @Output()
  onSelectTransaction: EventEmitter<Transaction> = new EventEmitter<Transaction>();
  @Output()
  onAddTransaction: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditTransaction: EventEmitter<Transaction> = new EventEmitter<Transaction>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addTransaction() {
    this.onAddTransaction.emit();
  }

  editTransaction(transaction: Transaction) {
    this.onEditTransaction.emit(transaction);
  }

  selectTransaction(transaction: Transaction) {
    this.onSelectTransaction.emit(transaction);
  }

  onFilter(filters: TransactionFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
