import { Pipe, PipeTransform } from '@angular/core';
import { Role } from 'src/app/commons/models/user.model';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(value: Role): unknown {
    switch (value) {
      case "admin":
        return "Admin";
      case "affiliate":
        return "Esercente";
    }
  }

}
