<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="transaction">Modifica ricarica: {{transaction?.id}}</span>
    <span *ngIf="!transaction">Nuova ricarica</span>
  </span>
  <span *ngIf="transaction">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteTransaction()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="transactionForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Data ora transazione</mat-label>
        <input matInput autocomplete="off" [ngxMatDatetimePicker]="date"
        formControlName="date">
        <mat-error>Campo obbligatorio</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(date)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #date [showSeconds]="true" color="primary"
        [enableMeridian]="false"></ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Importo" formControlName="amount" type="number" min="currentUser?.balance" (change)="setTwoNumberDecimal($event)" >
        <span matSuffix>Auram</span>
        <mat-error *ngIf="transactionForm.controls['amount'].errors?.required">Campo obbligatorio</mat-error>
        <mat-error *ngIf="transactionForm.controls['amount'].errors?.max">Importo superiore al saldo disponibile</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width" *ngIf="currentUser?.role == 'affiliate'">
        <input matInput placeholder="Codice cliente" formControlName="clientCode" type="text">
        <mat-error>Campo obbligatorio</mat-error>
        <mat-icon  matSuffix class="qrcode_icon" (click)="scan()" matTooltip="Compila codice tramite barcode">qr_code_scanner</mat-icon>
      
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <textarea matInput placeholder="Note" formControlName="note" rows="3"></textarea>
      </mat-form-field>
    </div>
  </form>
  <!-- {{transactionForm.value | json}}  -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="transactionForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="transactionForm.pristine || !transactionForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
