import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { User } from '../../../commons/models/user.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { UserFilters } from './../../../commons/models/user.model';

export type UsersColumn = "id" | "name" | "email" | "role" | "active" |"balance" | "sponsorCode"| "actions";

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      // transition(
      //   "expanded <=> collapsed",
      //   animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      // ),
    ]),
  ],

})
export class UserListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  users: Observable<User[]>;

  @Input()
  defaultFilters: UserFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: User | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: UsersColumn[] = ["active", "name","email", "role", "balance", "sponsorCode", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<UserFilters> = new EventEmitter<UserFilters>();

  @Output()
  onSelectUser: EventEmitter<User> = new EventEmitter<User>();
  @Output()
  onAddUser: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditUser: EventEmitter<User> = new EventEmitter<User>();
  @Output()
  onAddTransaction: EventEmitter<User> = new EventEmitter<User>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addUser() {
    this.onAddUser.emit();
  }

  editUser(user: User) {
    this.onEditUser.emit(user);
  }
  addTransaction(user: User) {
    this.onAddTransaction.emit(user);
  }

  selectUser(user: User) {
    this.onSelectUser.emit(user);
  }

  onFilter(filters: UserFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

}
