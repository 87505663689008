import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { UserDTO } from '../../models/user.model';
import { UserFilters } from './../../models/user.model';

export interface LoginSuccessDTO {
  success_token: string;
  user: UserDTO;
}

@Injectable({
  providedIn: "root"
})
export class LaravelUserService {


  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      getCurrentUser: environment.baseUrl + "/api/current_user",
      login: environment.baseUrl + "/api/login",
      logout: environment.baseUrl + "/api/auth/logout",
      list: environment.baseUrl + "/api/users",
      show: environment.baseUrl + "/api/user",
      store: environment.baseUrl + "/api/user",
      destroy: environment.baseUrl + "/api/user",
      changePassword: environment.baseUrl + "/api/user/change_password"
    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: UserFilters,
    includes?: string[]
  ): Observable<ListResultDTO<UserDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.search) params["search"] = "" + filters.search;
      if (filters.roles) params["roles[]"] = filters.roles;
      if (filters.active != undefined && filters.active != null) params["active"] = filters.active;
      if (filters.fromBalance) params["from_balance"] = filters.fromBalance;
      if (filters.toBalance) params["to_balance"] = filters.toBalance;
  }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<UserDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<UserDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public getCurrentUser(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.ROUTES.getCurrentUser);
  }

  public getUserById(id: number): Observable<UserDTO> {
    let params = { id: "" + id };
    return this.httpClient.get<UserDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }

  public upsert(user: UserDTO): Observable<UserDTO> {
    if (user.id) {
      return this.httpClient.put<UserDTO>(`${this.ROUTES.store}`, { user });
    } else {
      return this.httpClient.post<UserDTO>(`${this.ROUTES.store}`, { user });
    }
  }

  public login(email: string, password: string): Observable<LoginSuccessDTO> {
    let params = {
      email: email,
      password: password
    };
    return this.httpClient.post<LoginSuccessDTO>(this.ROUTES.login, params);
  }

  public logout(): Observable<any> {
    return this.httpClient.post<any>(this.ROUTES.logout, {});
  }

  public delete(id: number): Observable<any> {
    let params = { id: "" + id };
    return this.httpClient.delete(this.ROUTES.destroy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public changePassword(id: number, new_password: string): Observable<UserDTO> {
    return this.httpClient.put<UserDTO>(this.ROUTES.changePassword, { id, new_password });
  }
}
