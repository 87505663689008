import { Action, createReducer, on } from '@ngrx/store';

import { User, UserDTO } from '../../commons/models/user.model';
import * as UserActions from '../actions/user.actions';
import { UserFilters } from './../../commons/models/user.model';

export interface UserState {
  list: UserDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: UserFilters,
  dialogId: string,
  changePasswordDialogId: string,
  selectionDialogId: string,
  currentFormControlName: string;
  user: User
};

const initialState: UserState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  changePasswordDialogId: null,
  selectionDialogId: null,
  user: null,
  currentFormControlName: null
};

const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsersCompleted, (state, { users, currentPage, total, perPage, order, direction, includes }) => {
    return { ...state, list: users, currentPage, total, perPage, order, direction, includes };
  }),
  on(UserActions.userDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(UserActions.changePasswordDialogOpen, (state, { dialogId }) => {
    return { ...state, changePasswordDialogId:dialogId };
  }),
  on(UserActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(UserActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(UserActions.selectUser, (state, { filters, currentFormControlName }) => {
    return { ...state, currentPage: 1, filters, currentFormControlName };
  }),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

