import { AbstractControl } from '@angular/forms';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { Base, BaseDTO } from './base.model';
import { User, UserDTO } from './user.model';


export interface TransactionFilters {
  search?: string;
  user?: User;
  from?: Date;
  to?: Date;
  type?: boolean;
  fromAmount?: number;
  toAmount?: number;
}

export interface TransactionDTO extends BaseDTO {
  amount: number;
  date: string;
  note: string;
  client_code: string;
  user_id: number;
  user: UserDTO
}

export class Transaction extends Base {
  amount: number;
  date: Date;
  note: string;
  clientCode: string;
  user: User;
  constructor(source: TransactionDTO) {
    super(source);
    if (source) {
      this.amount = source.amount;
      this.date = new Date(source.date);
      this.note = source.note;
      this.clientCode = source.client_code;
      if (source.user) {
        this.user = new User(source.user);
        this.addLoadedRelation('user');
      }
    }
  }

  toDTO(): TransactionDTO {
    let result: TransactionDTO = <TransactionDTO>super.toDTO();
    result.amount = this.amount;
    result.date = formatDateForBackend(this.date, true);
    result.note = this.note;
    result.client_code = this.clientCode;
    result.user = this.user? this.user.toDTO() : null;
    result.user_id = this.user ? this.user.id: null;

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Transaction) {
    const formModel = formGroup.value;
    let transaction: Transaction = new Transaction(null);
    transaction.amount = formModel.amount;
    transaction.date = formModel.date;
    transaction.note = formModel.note;
    transaction.clientCode = formModel.clientCode;
    transaction.user = formModel.user;


    if (original) {
      transaction.id = original.id;
    }
    return transaction;
  }

  get outcome(): boolean {
    return !!this.clientCode || this.amount < 0;
  }

}
