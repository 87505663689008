import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/commons/models/user.model';

import { Transaction, TransactionDTO, TransactionFilters } from '../../commons/models/transaction.model';

export const loadTransactions = createAction('[Transactions] Load transactions', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: TransactionFilters, includes?: string[] }>());
export const loadTransactionsCompleted = createAction('[Transactions] Load transactions Completed', props<{ transactions: TransactionDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: TransactionFilters, includes?: string[] }>());
export const loadTransactionsFailed = createAction('[Transactions] Load transactions Failed', props<{ error: any }>());

export const changePage = createAction('[Transactions] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Transactions] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Transactions] Change filters', props<{ filters: TransactionFilters }>());

export const editTransaction = createAction('[Transactions] Edit transaction', props<{ transaction: Transaction }>());
export const addTransaction = createAction('[Transactions] add transaction',props<{ user: User }>());
export const transactionDialogOpened = createAction('[Transactions] Detail dialog opened', props<{ dialogId: string }>());
export const closeTransactionDialog = createAction('[Transactions] Close detail dialog');

export const saveTransaction = createAction('[Transactions] Save transaction', props<{ transaction: Transaction }>());
export const saveTransactionCompleted = createAction('[Transactions] Save transaction completed', props<{ transaction: TransactionDTO }>());
export const saveTransactionFailed = createAction('[Transactions] Save transaction failed', props<{ error: any }>());

export const deleteTransaction = createAction('[Transactions] Delete transaction', props<{ transaction: TransactionDTO }>());
export const deleteTransactionCompleted = createAction('[Transactions] Delete transaction completed', props<{ transaction: TransactionDTO }>());
export const deleteTransactionCancelled = createAction('[Transactions] Delete transaction cancelled');
export const deleteTransactionFailed = createAction('[Transactions] Delete transaction failed', props<{ error: any }>());

export const selectTransaction = createAction('[Transactions] Select transaction', props<{ filters?: TransactionFilters, currentFormControlName: string }>());
export const selectionDialogOpened = createAction('[Transactions] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Transactions] Close selection dialog');
export const transactionSelected = createAction('[Transactions] Transaction selected', props<{ transaction: TransactionDTO }>());

export const exportRecords = createAction("[Transactions] Export records", props<{ filters?: TransactionFilters }>());
export const exportRecordsCompleted = createAction("[Transactions] Export records completed",props<{ blob: Blob }>());
export const exportRecordsFailed = createAction("[Transactions] Export records failed",props<{ error: any }>());

export const scanTransactionCode = createAction('[Transactions] Scan Transaction code');
export const scanDialogOpened = createAction('[Transactions] Scan dialog opened', props<{ scanDialogId: string }>());
export const closeScanDialog = createAction('[Transactions] Close scan dialog');
export const scanSuccess = createAction('[Transactions] Scan  Success', props<{ code: string }>());
export const scanError = createAction('[Transactions] Scan  Error');
