import { TransactionFiltersComponent } from './transaction-filters/transaction-filters.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { MaterialModule } from './../material/material.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ShowForDirective } from './directives/show-for.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { UserRolePipe } from './pipes/user-role.pipe';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { UserFieldComponent } from './user-field/user-field.component';
import { UserFiltersComponent } from './user-filters/user-filters.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserSelectionComponent } from './user-selection/user-selection.component';
import { UsersFieldComponent } from './users-field/users-field.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CustomDecimalPipe } from './pipes/custom-decimal.pipe';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    FlexLayoutModule,
    NgxMaterialTimepickerModule.setLocale('it-IT'),
    MatSlideToggleModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  providers: [DecimalPipe],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    UserRolePipe,
    DateFormatPipe,
    CustomDecimalPipe,
    UserListComponent,
    TransactionListComponent,
    UserFiltersComponent,
    ShowForDirective,
    UserSelectionComponent,
    UserFieldComponent,
    UsersFieldComponent,
    TransactionFiltersComponent,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    UserRolePipe,
    DateFormatPipe,
    CustomDecimalPipe,
    UserListComponent,
    UserFiltersComponent,
    UserSelectionComponent,
    UserFieldComponent,
    UsersFieldComponent,
    TransactionListComponent,
    TransactionFiltersComponent,
    ShowForDirective,
  ],
  entryComponents: [
    ConfirmDialogComponent,
  ]
})
export class SharedModule { }
