import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDecimal'
})
export class CustomDecimalPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe){}

  transform(value: number | string): string | null {
    const preFormatted = this.decimalPipe.transform(value, '1.2-2');
     if(preFormatted) {
      const point = /\./gi;
      const comma = /\,/gi;
      return preFormatted.replace(point, ' ').replace(comma, '.');
    }
    return null;
  }
}
