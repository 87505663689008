import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Role, UserFilters } from '../../../commons/models/user.model';

@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss']
})
export class UserFiltersComponent {

  roles: Role[] = ["admin", "affiliate"];

  @Output()
  onFilter: EventEmitter<UserFilters> = new EventEmitter();

  @Input()
  showActiveFilter: boolean = false;

  private _defaultFilters: UserFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      roles: [["admin", "affiliate"]],
      active: "",
      fromBalance: "",
      toBalance: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getUserFilters());
  }

  private _getUserFilters(): UserFilters {
    let filters: UserFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.roles = values.roles;
      filters.active = values.active;
      filters.fromBalance = values.fromBalance;
      filters.toBalance = values.toBalance;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      roles: [],
      fromBalance: "",
      toBalance: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): UserFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: UserFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          roles: this.defaultFilters.roles,
          active: this.defaultFilters.active,
          fromBalance: this.defaultFilters.fromBalance,
          toBalance: this.defaultFilters.toBalance
        })
      }
    }
  }

  setTwoNumberDecimal($event) {
    $event.target.value = parseFloat($event.target.value).toFixed(2);
  }
}
