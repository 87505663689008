import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { map, Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/commons/models/user.model';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import {TransactionFilters } from '../../../commons/models/transaction.model';

@Component({
  selector: 'app-transaction-filters',
  templateUrl: './transaction-filters.component.html',
  styleUrls: ['./transaction-filters.component.scss']
})
export class TransactionFiltersComponent {

  @Output()
  onFilter: EventEmitter<TransactionFilters> = new EventEmitter();

  @Input()
  showActiveFilter: boolean = false;

  private _defaultFilters: TransactionFilters;
  filtersForm: FormGroup;
  currentUser: User;
  private unsubscribe$ = new Subject<void>();

  constructor(private fb: FormBuilder, private store$: Store<AppState>) {
    this.store$.pipe(select(AuthSelectors.getCurrentUser), takeUntil(this.unsubscribe$), map(dto => dto ? new User(dto) : null)).subscribe(user => this.currentUser = user);
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      user: "",
      from: "",
      to: "",
      type: "",
      fromAmount: "",
      toAmount: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getTransactionFilters());
  }

  private _getTransactionFilters(): TransactionFilters {
    let filters: TransactionFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.user = values.user;
      filters.from = values.from;
      filters.to = values.to;
      filters.type = values.type;
      filters.fromAmount = values.fromAmount;
      filters.toAmount = values.toAmount;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      user:"",
      from:"",
      to:"",
      fromAmount:"",
      toAmount:"",

    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): TransactionFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: TransactionFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          user: this.defaultFilters.user,
          from: this.defaultFilters.from,
          to: this.defaultFilters.to,
          type: this.defaultFilters.type,
          fromAmount: this.defaultFilters.fromAmount,
          toAmount: this.defaultFilters.toAmount,
        })
      }
    }
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setTwoNumberDecimal($event) {
    $event.target.value = parseFloat($event.target.value).toFixed(2);
  }
}
