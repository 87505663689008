import { Action, createReducer, on } from '@ngrx/store';

import { Transaction, TransactionDTO } from '../../commons/models/transaction.model';
import * as TransactionActions from '../actions/transaction.actions';
import { TransactionFilters } from './../../commons/models/transaction.model';

export interface TransactionState {
  list: TransactionDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: TransactionFilters,
  dialogId: string,
  selectionDialogId: string,
  currentFormControlName: string;
  transaction: Transaction;
  scanDialogId: string;
  code: string;
};

const initialState: TransactionState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  transaction: null,
  currentFormControlName: null,
  scanDialogId: null,
  code: null
};

const transactionReducer = createReducer(
  initialState,
  on(TransactionActions.loadTransactionsCompleted, (state, { transactions, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: transactions, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(TransactionActions.transactionDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(TransactionActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(TransactionActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(TransactionActions.selectTransaction, (state, { filters, currentFormControlName }) => {
    return { ...state, currentPage: 1, filters, currentFormControlName };
  }),
  on(TransactionActions.scanDialogOpened, (state, { scanDialogId }) => {
    return { ...state, scanDialogId, code: null };
  }),
  on(TransactionActions.scanSuccess, (state, { code }) => {
    return { ...state, code };
  }),

);

export function reducer(state: TransactionState | undefined, action: Action) {
  return transactionReducer(state, action);
}

