import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAuth from './auth.reducers';
import * as fromSidebar from './sidebar.reducer';
import * as fromUser from './user.reducer';
import * as fromTransaction from './transaction.reducer';

export interface AppState {
  sidebar: fromSidebar.SidebarState;
  router: fromRouter.RouterReducerState<any>;
  auth: fromAuth.AuthState;
  user: fromUser.UserState;
  transaction: fromTransaction.TransactionState;
}

export const reducers: ActionReducerMap<AppState> = {
  sidebar: fromSidebar.reducer,
  router: fromRouter.routerReducer,
  auth: fromAuth.reducer,
  user: fromUser.reducer,
  transaction: fromTransaction.reducer
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { auth: ["token"] },
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, fromAuth.clearState];

export const selectRouter = createFeatureSelector<
  AppState,
  fromRouter.RouterReducerState<any>
>("router");

export const selectSidebarState = createFeatureSelector<fromSidebar.SidebarState>(
  "sidebar"
);
export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  "auth"
);
export const selectUserState = createFeatureSelector<fromUser.UserState>(
  "user"
);
export const selectTransactionState = createFeatureSelector<fromTransaction.TransactionState>(
  "transaction"
);

const {
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = fromRouter.getSelectors(selectRouter);

export const selectRouteId = selectRouteParam("id");
export const selectStatus = selectQueryParam("status");
